import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { CustomText, PrimaryCta, TeamMembers } from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import CreateCollectionModal from "./createCollection";
import OrganisationListScreen from "../brandSetiing/organisationListScreen";

function CollectionOrganisationListScreen(props) {
  const [organisationList, setOrganisationList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [brandIsOpen, setBrandIsOpen] = useState(false);
  const [orgId,setOrgId]=useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showBrand,setShowBrand]=useState(true);
  const [brandList,setBrandList]=useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [collectionList, setCollectionList] = useState([]);

  console.log("organisationList",organisationList)

  var navigate = useNavigate();

  useEffect(() => {
    getOrgList();
  }, [searchTerm]);

  useEffect(() => {
    getCollectionList();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getOrgList() {
    var params = {

    }
      if (searchTerm) {
        params["search"] = searchTerm.toLowerCase();
      }

    Core.getOrgListAdmin(
      getOrgListAdminSuccess,
      getOrgListAdminFailed,
      (status) => {
        setLoading(status);
      },
      params
    );
  }

  function getOrgListAdminSuccess(response_data) {
    setOrganisationList(response_data.organization_list);
  }

  function getOrgListAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  
  function getCollectionList() {
    const params = {
      org_id:orgId
    };
    Core.getCollectionList(
      getCollectionListSuccess,
      getCollectionListFailed,
      updateProgress,
      params
    );
  }

  function getCollectionListSuccess(response_data) {
    setCollectionList(response_data.collection_list);
  }

  function getCollectionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "var(--general-secondary-color)",
      borderColor: "var( --blue-blue-500)",
      textAlign: "center",
      zIndex: "5",
      height: "80%",
      display: "flex",
      flexDirection: "column",
      minWidth: "400px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.86)",
      zIndex: 10,
    },
  };

  
  console.log("orgIddddd",orgId)

  function openModal() {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }
  function closeModal() {

    setModalIsOpen(false);
  }

  function Collection(orgId){
    navigate(`/collection/${orgId}`)
  }


  function coll(){
    navigate("/collection")
  }
  
  const brandClose = () => {
    setBrandIsOpen(false);
  };

  console.log("CollectionOrganisationListScreen")
  return (
    // <>
    //   <Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
    //     {
    //     <div className="org-cont" style={{marginBottom:"10px"}} >
    //     <div className="select-orgs" >
    //     <div className="select-org" >
    //             <div>
    //             <CustomText
    //              p
    //              text={"Select Organisation"}
    //              fontSize={"font_size_xl"}
    //              textColor={"text_color_primary"}
    //              fontWeight={"rubik_regular"}
    //              />
    //             </div>
    //             <div onClick={closeModal} className="close-icon-brand-list">
    //             <Icon iconName="CloseRoundButtonIcon" />
    //             </div>
    //     </div>
    //     <div className="searchbar">
    //     <input
    //        type="text"
    //        placeholder="Search "
    //        value={searchTerm}
    //        onChange={(e) => setSearchTerm(e.target.value)}
    //        style={{
    //        padding: '10px',
    //        fontSize: '16px',
    //        width: '95%',
    //        marginBottom: '10px'
    //               }}
    //      />
    //     </div>
    //     </div>
    //     <div style={{justifyContent:"left"}} >
    //     {isLoading ? (
    //       <Loader />
    //     ) : (
    //       <>
    //         <div className="org-list-height">
    //           {organisationList &&
    //             organisationList.map((item) => {
    //               return (
    //                 <div style={{cursor:"pointer",display:"flex",flexDirection:"column",marginBottom:"10px"}} 
    //                 onClick={() => {
    //                     Collection(item.org_data.org_id);
    //                   }}>
    //                   <div>
    //                   {item.org_data.name}
    //                   </div>
    //                   <div>
    //                   {`#${item.org_data.org_id}`}
    //                   </div>
    //                 </div>
    //               );
    //             })}
    //         </div>
    //       </>
    //     )}
    //     </div>
    //     </div>
    //     }
    //   </Modal>
    // </>
    <Modal>
      <div>
      {!brandIsOpen && <OrganisationListScreen closeModal={brandClose} />}
    </div>
    </Modal>
  );
}

export default CollectionOrganisationListScreen;
