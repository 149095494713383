import React from "react";
import { CustomText, CustomTextInput, PrimaryCta } from "../../components";
import { ModalComponent } from "../../components";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import utils from "../../common/utils";
import CustomTable from "../../components/customTable";
import exportToCSV from "../../common/exportToCSV";
import { ToastContainer, toast } from "react-toastify";
import CompanySettingModal from "../companyDetails/addressDetailVerify";
import { useNavigate, useParams } from "react-router-dom";

const limit = 1;
const OrganisationTransactionList = (props) => {
  const [transactionList, setTransactionList] = useState([]);
  const [organisationDetail, setOrganisationDetail] = useState({});
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);
  const [loadingCredit, setLoadingCredit] = useState(false);
  const [addAmount, setAddAmount] = useState("");
  const [balance, setBalance] = useState("");
  const [credit, setCredit] = useState("");
  const [addCredit, setAddCredit] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [address, setAddress] = useState({});
  const [openModal2, setOpenModal] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [effectiveLoad2, setEffectiveLoad2] = useState(false);
  const [openSnackbar] = useSnackbar();
  const navigate = useNavigate();
  const id = useParams().id;
  // console.log("transId",id)
  useEffect(() => {
    getTransListAdmin();
    getOrgDataUser();
  }, []);

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState);
  };
  const toggleModal2 = () => {
    setOpenModal3((prevState) => !prevState);
  };

  function getOrgDataUser() {
    const params = {
      org_id: id,
      limit: perPage,
      start: next,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      () => {},
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setAddress(response_data.address_data.address_premise);
    setBalance(response_data?.org_data?.org_data?.balance);
    setCredit(response_data?.org_data?.org_data?.credits);
    setHistory(response_data["transaction_list"]);
    setNext(response_data?.pagination_data?.next);
    // setLoading(false);
    setOrganisationDetail(response_data);
    // console.log("responsedddddddd",response_data)
  }
  // console.log("orgDetail",organisationDetail)
  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList);
  }
  function getTransListAdmin() {
    const params = {
      org_id: id,
    };
    Core.getTransListAdmin(
      getTransListAdminSuccess,
      getTransListAdminFailed,
      () => setLoading(),
      params
    );
  }

  function getTransListAdminSuccess(response_data) {
    setTransactionList(response_data.transaction_list);
    setLoading(false);
    // console.log("transresponse",response_data)
  }
  // console.log("transactionList",transactionList)

  function getTransListAdminFailed(errorList) {
    openSnackbar(errorList);
  }

  function updateProgress(status) {
    setLoading(status);
  }

  //   const handlePageChange = (page) => {
  //     getTransactionHistoryList(page);
  //   };

  function postCreateWalletAdmin() {
    var params = {
      amount: addAmount,
      org_id: id,
      identifier_id: id,
      identifier: "org",
    };
    if (minBalanceValidation())
      Core.postCreateWalletAdmin(
        postCreateWalletAdminSuccess,
        postCreateWalletAdminFailed,
        (status) => setEffectiveLoad(status),
        params
      );
  }
  function postCreateWalletAdminSuccess(response_data) {
    createOrder(response_data?.org_data);
    toggleModal();
    // console.log("create-wallet",response_data)
  }
  function postCreateWalletAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postCreateCreditAdmin() {
    var params = {
      credit: addCredit,
      identifier_id: id,
      identifier: "org",
    };
    Core.postCreateCreditAdmin(
      postCreateCreditAdminSuccess,
      postCreateCreditAdminFailed,
      (success) => setEffectiveLoad2(success),
      params
    );
  }
  function postCreateCreditAdminSuccess(response_data) {
    createCreditOrder(response_data.credit);
    toggleModal2();
    // console.log("add-credit",response_data)
  }
  function postCreateCreditAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const createOrder = (order_data) => {
    // Make an API call to your server to create a test order
    const options = {
      key: order_data.payment_gateway_id,
      order_id: order_data?.pg_order_id,
      amount: order_data?.amount,
      currency: "INR",
      handler: function (response) {
        postCreateWalletAdmin();
      },
    };
  };
  const createCreditOrder = (order_data) => {
    // Make an API call to your server to create a test order
    const options = {
      credit: order_data?.credit,
      org_id: order_data?.identifier_id,
      handler: function (response) {
        postCreateCreditAdmin();
      },
    };
  };

  function minBalanceValidation() {
    if (addAmount < 5000) {
      toast("Amount should not be less than 5000.");
      return false;
    }
    return true;
  }
  const tableSchema = [
    {
      name: "Inluencer ID",
      selector: (row) => row["influencer_id"],

      cell: (data) => (
        <span
        // onClick={() => redirectContractDetail(data["assignment_id"])}
        >
          {data["influencer_id"] ? data["influencer_id"] : "-"}
        </span>
      ),
    },

    {
      name: "Type",
      selector: (row) => row["transaction_type"],
    },

    {
      name: "Date",
      selector: (row) => {
        return utils.convertUnixToDate(row.transaction_date);
      },

      maxWidth: "200px",
    },
    {
      name: "Amount",
      selector: (row) => {
        return "Rs " + row["transaction_amount"];
      },
    },
    {
      name: "Status",
      selector: (row) => row["transaction_status"],
    },
  ];

  const tableData = transactionList.map((e) => ({
    influencer_id: e.influencer_id,
    transaction_type: e.transaction_type,
    transaction_date: e.transaction_date,
    transaction_amount: e.transaction_amount,
    transaction_status: e.transaction_status,
  }));

  // console.log("tableData",tableData)

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)",
        color: "var(--gray-gray-300)",
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  let gst_value = (addAmount * 18) / 100;
  let total_pay = Number(addAmount) - Number(gst_value);

  const TransactionCSV = () => {
    const data = transactionList.map((item) => {
      return {
        transaction_amount: item.transaction_amount,
        transaction_type: item.transaction_type,
        transaction_date: utils.convertUnixToDate(item.transaction_date),
        influencer_id: item.influencer_id,
      };
    });

    return (
      <div>
        <PrimaryCta
          sixth
          textColor={"text_color_primary"}
          iconName={"downloadIcon"}
          fontSize={"font_size_large"}
          className="transaction-csv-btn"
          text={"Export to CSV"}
          onClick={() => exportToCSV(data)}
        />
      </div>
    );
  };

  function checkAddressThenPayment() {
    if (
      organisationDetail?.org_data?.org_data?.pan_number &&
      organisationDetail?.address_data?.address_premise
    ) {
      postCreateWalletAdmin();
    } else {
      openModal();
    }
  }
  function checkAddressThenCredit() {
    if (
      organisationDetail?.org_data?.org_data?.pan_number &&
      organisationDetail?.address_data?.address_premise
    ) {
      postCreateCreditAdmin();
    } else {
      openModal();
    }
  }

  function openModal() {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  if (!props.isActive) {
    return <></>;
  }
  return (
    <div className="">
      <div className="m-t-40">
        <>
          <div className="wallet-credit" style={{ display: "flex" }}>
            <div className="m-l-20">
              <CustomText
                p
                text={"Wallet"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="button2 m-b-8"
              />
              <div className="wallet-screen-add-inner-container">
                <div className="wallet-screen-add-balance">
                  <div>
                    <CustomText
                      p
                      text={"Enter Amount with GST"}
                      textColor={"text_color_primary"}
                      className="button2 m-b-10"
                    />
                    <CustomTextInput
                      className="update_input_text "
                      value={addAmount}
                      onChange={(e) => {
                        const value = e.target.value;
                        const newValue = value.replace(/[^\d]/g, "");
                        setAddAmount(newValue);
                      }}
                      type="number"
                    />
                  </div>

                  <div>
                    <PrimaryCta
                      fifth
                      onClick={() => toggleModal()}
                      className="wallet-screen-add-btn"
                      fontSize={"font_size_large"}
                      text={"Add Balance"}
                      isLoading={loadingPay}
                      disabled={addAmount == 0 ? true : false}
                    />
                  </div>
                </div>
                <div className="amount-balance-section">
                  <CustomText
                    p
                    text={"Amount Balance -"}
                    textColor={"text_grey_400_color"}
                    className="Caption-3"
                  />
                  {loading ? (
                    <CustomText p text={`-`} className="Caption-3" />
                  ) : (
                    <CustomText
                      p
                      text={`\u00A0  ₹${balance}`}
                      textColor={
                        balance <= 1000 ? "text_color_red" : "text_color_green"
                      }
                      className="Caption-3"
                    />
                  )}
                </div>
                <div className="amount-balance-section-detail">
                  <CustomText
                    p
                    text={"Amount  "}
                    textColor={"text_color_primary"}
                    className="subtitle1 w-129"
                  />
                  <CustomText
                    p
                    text={addAmount == 0 ? "-" : addAmount}
                    textColor={"text_grey_400_color"}
                    className="button1"
                  />
                </div>
                <div className="amount-balance-section-detail">
                  <CustomText
                    p
                    text={"GST (18%)  "}
                    textColor={"text_color_primary"}
                    className="subtitle1 w-129"
                  />
                  <CustomText
                    p
                    text={gst_value == 0 ? "-" : gst_value}
                    textColor={"text_grey_400_color"}
                    className="button1"
                  />
                </div>
                <div className="amount-balance-section-detail">
                  <CustomText
                    p
                    text={"Without GST"}
                    textColor={"text_color_primary"}
                    className="subtitle1 w-129"
                  />
                  <CustomText
                    p
                    text={total_pay == 0 ? "-" : total_pay}
                    textColor={"text_grey_400_color"}
                    className="button1 "
                  />
                </div>
              </div>
            </div>
            <div className="m-r-10 m-l-10">
              <CustomText
                p
                text={"Credit"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="button2 m-b-8"
              />
              <div className="credit-screen-add-inner-container">
                <div className="credit-screen-add-balance">
                  <div>
                    <CustomText
                      p
                      text={"Enter Credit"}
                      textColor={"text_color_primary"}
                      className="button2 m-b-10"
                    />
                    <div style={{ display: "flex" }}>
                      <CustomTextInput
                        className="update_input_text_credit "
                        value={addCredit}
                        onChange={(e) => {
                          const value = e.target.value;
                          const newValue = value.replace(/[^\d]/g, "");
                          setAddCredit(newValue);
                        }}
                        type="number"
                      />
                      <div>
                        <PrimaryCta
                          fifth
                          onClick={() => toggleModal2()}
                          className="credit-screen-add-btn"
                          fontSize={"font_size_large"}
                          text={"Add Credit"}
                          isLoading={loadingCredit}
                          disabled={addCredit == 0 ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="amount-balance-section">
                  <CustomText
                    p
                    text={"Credit Balance -"}
                    textColor={"text_grey_400_color"}
                    className="Caption-3"
                  />
                  {loading ? (
                    <CustomText p text={`-`} className="Caption-3" />
                  ) : (
                    <CustomText
                      p
                      text={`\u00A0  ${credit}`}
                      textColor={
                        credit <= 10 ? "text_color_red" : "text_color_green"
                      }
                      className="Caption-3"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="campaignlist-header m-t-30 m-r-32">
            <CustomText
              p
              text={"Transaction History"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
              className="typographyH7"
            />
            <div>
              <TransactionCSV />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="custom-table">
              <CustomTable
                className="CustomTable"
                tableSchema={tableSchema}
                tableData={tableData}
                paginationPerPage={15}
                rowPaddingTop={16}
                rowPaddingBottom={16}
                // progressPending={loading}
                customStyles={customStyles}
                // paginationServer
                // paginationTotalRows={next}
                // onChangeRowsPerPage={getTransactionHistoryList}
                // onChangePage={handlePageChange}
                pagination
              />
            </div>
          )}
        </>
      </div>
      {modalIsOpen && <CompanySettingModal closeModal={closeModal} />}
      {
        <ModalComponent
          isOpen={openModal2}
          onRequestClose={() => toggleModal()}
          customStyle={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999999,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              // borderColor: "#f55879",
              backgroundColor: "#F0F3F9",

              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
            },
          }}>
          <div className="">
            <CustomText
              h5
              text={"Are you sure to Add Balance?"}
              textColor={"#040408"}
              className="m-b-8 typographyBoldH5"
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex ",
              justifyContent: "center",
              gap: "30px",
            }}>
            <PrimaryCta
              invert
              className="back-btn-campaign"
              onClick={() => toggleModal()}
              fontSize={"font_size_large"}
              text={"No"}
            />
            <PrimaryCta
              third
              fontSize={"font_size_regular"}
              className="header-credit-right"
              text={"Yes"}
              isLoading={effectiveLoad}
              onClick={() => checkAddressThenPayment()}
            />
          </div>
        </ModalComponent>
      }
      {
        <ModalComponent
          isOpen={openModal3}
          onRequestClose={() => toggleModal2()}
          customStyle={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999999,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              // borderColor: "#f55879",
              backgroundColor: "#F0F3F9",

              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
            },
          }}>
          <div className="">
            <CustomText
              h5
              text={"Are you sure to Add Credit?"}
              textColor={"#040408"}
              className="m-b-8 typographyBoldH5"
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex ",
              justifyContent: "center",
              gap: "30px",
            }}>
            <PrimaryCta
              invert
              className="back-btn-campaign"
              onClick={() => toggleModal2()}
              fontSize={"font_size_large"}
              text={"No"}
            />
            <PrimaryCta
              third
              fontSize={"font_size_regular"}
              className="header-credit-right"
              text={"Yes"}
              isLoading={effectiveLoad2}
              onClick={() => checkAddressThenCredit()}
            />
          </div>
        </ModalComponent>
      }
    </div>
  );
};

export default OrganisationTransactionList;
