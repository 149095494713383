import React, { useState, useEffect } from "react";
import { CustomButton, CustomText } from "../../components";
import Core from "../../common/clientSdk";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import * as CampaignData from "../../common/data/campaign";
const Share = () => {
  const [openRow, setOpenRow] = useState([]);
  const [influencerData, setInfluencerData] = useState([]);
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [btnLoader, setBtnLoader] = useState({});
  const [btnLoaderRejected, setBtnLoaderRejected] = useState({});

  useEffect(() => {
    getCollectionInfluencerListByShareKey();
  }, []);

  console.log("process env", process.env);

  console.log("influencerData", influencerData);

  const { share_key } = useParams();
  console.log("share_key_id", share_key);
  function getCollectionInfluencerListByShareKey() {
    var params = {
      share_key: share_key,
    };
    Core.getCollectionInfluencerListByShareKey(
      getCollectionInfluencerListByShareKeySuccess,
      getCollectionInfluencerListByShareKeyFailed,
      () => {},
      params
    );
  }
  function getCollectionInfluencerListByShareKeySuccess(response) {
    console.log("ShareKeySuccess", response);
    setInfluencerData(response.collection_deep_data);
  }
  function getCollectionInfluencerListByShareKeyFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }
  function updateCollectionReviewStatus(reviewStatus, influencer_id) {
    var params = {
      review_status: reviewStatus,
      influencer_id: influencer_id,
      collection_id: influencerData.collection_data.collection_id,
    };
    Core.updateCollectionReviewStatus(
      updateCollectionReviewStatusSuccess,
      updateCollectionReviewStatusFailed,
      (status) => setBtnLoader({ [influencer_id]: status }),
      params
    );
  }
  function updateCollectionReviewStatusSuccess(response) {
    console.log("ShareKeySuccess1", response);
    setInfluencerData((prevState) => ({
      ...prevState,
      collection_influencer_list: prevState?.collection_influencer_list?.map(
        (influencer) =>
          influencer?.influencer_data?.influencer_id ===
          response.influencer_deep_data?.influencer_data?.influencer_id
            ? { ...influencer, ...response.influencer_deep_data } // Update only matching influencer
            : influencer // Keep unchanged if no match
      ),
    }));
  }
  function updateCollectionReviewStatusFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }
  function updateCollectionReviewStatusRejected(reviewStatus, influencer_id) {
    var params = {
      review_status: reviewStatus,
      influencer_id: influencer_id,
      collection_id: influencerData.collection_data.collection_id,
    };
    Core.updateCollectionReviewStatus(
      updateCollectionReviewStatusRejectedSuccess,
      updateCollectionReviewStatusRejectedFailed,
      (status) => setBtnLoaderRejected({ [influencer_id]: status }),
      params
    );
  }
  function updateCollectionReviewStatusRejectedSuccess(response) {
    console.log("ShareKeySuccess1", response);
    setInfluencerData((prevState) => ({
      ...prevState,
      collection_influencer_list: prevState?.collection_influencer_list?.map(
        (influencer) =>
          influencer?.influencer_data?.influencer_id ===
          response.influencer_deep_data?.influencer_data?.influencer_id
            ? { ...influencer, ...response.influencer_deep_data } // Update only matching influencer
            : influencer // Keep unchanged if no match
      ),
    }));
  }

  function updateCollectionReviewStatusRejectedFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }

  function getReviewStatus(status) {
    if (status == "ACCEPTED") {
      return (
        <CustomText p text={"Accepted"} textColor="text_completed_color" />
      );
    } else {
      return <CustomText p text={"Rejected"} textColor="text_debit_color" />;
    }
  }

  const openTable = (index) => {
    setOpenRow((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 && platforms_data[0].platform_id != null && (
          <div>
            {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
          </div>
        )}
        <div style={{ marginTop: 7 }} className="flex-row">
          {platforms_data.map((item, index) => {
            if (Utils.getSocialIcon(item.platform_id)) {
              return (
                <>
                  <div
                    key={index}
                    className={"platform-name"}
                    data-tooltip-id={`tooltip-${influencer_id}${item.platform_id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`${influencer_id}${item.platform_id}`)
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(`${influencer_id}${item.platform_id}`)
                    }
                    onClick={() =>
                      handlePlatformClick(
                        Utils.getSocialUrl(item.platform_id, item.identifier_id)
                      )
                    }
                  >
                    <Icon
                      iconName={
                        hoveredIcons[`${influencer_id}${item.platform_id}`]
                          ? Utils.getSocialIconHover(item.platform_id)
                          : Utils.getSocialIcon(item.platform_id)
                      }
                      height={14}
                      width={14}
                    />
                  </div>
                  {/* <ReactTooltip
                    style={{ zIndex: 20 }}
                    id={`tooltip-${influencer_id}${item.platform_id}`}
                    place="bottom"
                    content={item.identifier_id}
                  /> */}
                </>
              );
            }
            return null;
          })}
        </div>
      </>
    );
  };

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformData = (platforms_data, key) => {
    console.log("platforms_data", platforms_data);

    const selectedPlatformData = getSelectedPlatformData(platforms_data);

    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  function openSocialMedia(platforms_data, key) {
    const openUrl = getSelectedPlatformData(platforms_data);
    window.open(
      Utils.getSocialUrl(openUrl.platform_id, openUrl.identifier_id, "_bank")
    );
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }
  return (
    <div className="share">
      <table class="custom_table bordered">
        <thead>
          <tr>
            <th data-label className="share-influencer-table-heading ">
              Influencer Details
            </th>
            <th data-label>Followers</th>
            <th data-label>Avg Views </th>
            <th data-label>E.R. (%)</th>
            <th data-label>Asking Price</th>
            <th data-label>Action</th>
          </tr>
        </thead>
        <tbody>
          {influencerData?.collection_influencer_list?.map((item, index) => (
            <tr
              class={openRow[index] ? "close" : "open"}
              key={item?.influencer_data?.influencer_id}
              onClick={() => openTable(index)}
            >
              <td className=" tr-1" data-label=" ">
                <div
                  className="flex-row"
                  onClick={() => openSocialMedia(item?.platforms_data)}
                >
                  <div className="tc-1">
                    <img
                      src={
                        item?.influencer_data.profile_picture
                          ? Utils.generateInfluencerDpUrl(
                              item?.influencer_data.influencer_id,
                              item?.influencer_data?.profile_picture
                            )
                          : require("../../resources/images/user-copy.png")
                      }
                      className="share-table-img"
                    />
                  </div>
                  <div class="share-infuencer-detail">
                    <div>{item?.influencer_data?.name}</div>
                    <div className="share-place">
                      {getInflencerLocation(item?.influencer_data)}
                    </div>
                  </div>
                </div>
              </td>

              <td className="table-column-data" data-label="Followers -">
                {!Utils.isObjectOrNullUndefinedZero(
                  Number(formatPlatformData(item?.platforms_data, "followers"))
                )
                  ? formatPlatformData(item?.platforms_data, "followers")
                  : "-"}
              </td>
              <td className="table-column-data" data-label="Avg View -">
                {!Utils.isObjectOrNullUndefinedZero(
                  Number(formatPlatformData(item?.platforms_data, "avg_views"))
                )
                  ? formatPlatformData(item?.platforms_data, "avg_views")
                  : "-"}
              </td>
              <td className="table-column-data" data-label="E.R. -">
                {!Utils.isObjectOrNullUndefinedZero(
                  Number(
                    formatPlatformData(item?.platforms_data, "engagement_rate")
                  )
                )
                  ? Number(
                      formatPlatformData(
                        item?.platforms_data,
                        "engagement_rate"
                      )
                    ).toFixed(2)
                  : "-"}
              </td>
              <td className="table-column-data" data-label="Asking price -">
                {item?.influencer_data?.price ? (
                  <CustomText p text={item?.influencer_data?.price} />
                ) : (
                  "-"
                )}
              </td>
              <td className="table-column-data-btn" data-label="">
                {
                  <span>
                    {item?.influencer_data?.review_status ? (
                      getReviewStatus(item?.influencer_data?.review_status)
                    ) : (
                      <div className="accept-reject">
                        <div className="accept">
                          <CustomButton
                            text={"Accept"}
                            onClick={() =>
                              updateCollectionReviewStatus(
                                "ACCEPTED",
                                item?.influencer_data?.influencer_id
                              )
                            }
                            isLoading={
                              btnLoader[item?.influencer_data.influencer_id]
                            }
                            fontSize={"font_size_small"}
                            borderRadius={4}
                            padding={{
                              top: 12,
                              right: 12,
                              bottom: 12,
                              left: 12,
                            }}
                            textColor={"text_color_light"}
                            background={"#090A14"}
                            border={"none"}
                            boxShadow={"none"}
                            hoverColor={"var(--hover-color, #1977F2)"}
                          />
                        </div>
                        <div className="reject">
                          <CustomButton
                            text={"Reject"}
                            onClick={() =>
                              updateCollectionReviewStatusRejected(
                                "REJECTED",
                                item?.influencer_data.influencer_id
                              )
                            }
                            isLoading={
                              btnLoaderRejected[
                                item?.influencer_data.influencer_id
                              ]
                            }
                            fontSize={"font_size_small"}
                            borderRadius={4}
                            padding={{
                              top: 12,
                              right: 12,
                              bottom: 12,
                              left: 12,
                            }}
                            textColor={"#090A14"}
                            background={"White"}
                            border={"none"}
                            boxShadow={"none"}
                            hoverColor={"#e6e7e8"}
                          />
                        </div>
                      </div>
                    )}
                  </span>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        {/* <Pricing/> */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Share;
