import React, { useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    CustomCheckBoxCircular,
    CustomText,
    Image,
    PrimaryCta,
  } from "../../components";
import SideMenu from "../../screens/sideMenu";
import Header from "../header";
import { Loader } from "../loader";
import Core from '../../common/clientSdk';
import { toast } from "react-toastify";
import OrganisationDetailsScreen from "./organisationDetailsScreen";
import OrganisationTransactionList from "./organisationTransactionList";
import OrganisationBrandList from "./organisationDetailsBrandList";

const OrganisationParentDetails=()=>{
    const[organisationDetail,setOrganisationDetail]=useState({})
    const [activeTab, setActiveTab] = useState("detail-page");
    const [isLoading, setLoading] = useState(false);
    
    useEffect(()=>{
        adminGetDataSelf()
    },[]  
    );

    const id = useParams().id;

    function adminGetDataSelf(progress_cb){
        var params = {
            // org_id:"2gqcqb"
          };
        Core.adminGetDataSelf(
            getOrgDataAdminSuccess,
            getOrgDataAdminFailed,
            ()=>{},
            progress_cb,
            params
        )
     }
     function getOrgDataAdminSuccess(response){
        // console.log("response",response)
        setOrganisationDetail(response)

     }
      function getOrgDataAdminFailed(errorList){
        toast.info(errorList.map((item) => item.m).join(", "));
      }
    
    // console.log("organisationDetail",organisationDetail)

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
      };
      // console.log("activeTab",activeTab)
    return(
        <div>
      <div className="dashboard-container">
        <SideMenu />
        <div className="main-screen">
          <Header title="Campaign Details" />

          <div className="internal-page-container">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="campaign-text-heading">
                  <CustomText
                    h5
                    text={"Organisation Details"}
                    className="typographyH7"
                  />
                </div>
                <div
                  className={
                    activeTab === "detail-page?"
                  }
                >
                  <div className="campaign-detail-upper-container">
                    <div className="campaign-detail-tab-header">
                      <div
                        className={activeTab === "detail-page" ? "active" : ""}
                        onClick={() => handleTabClick("detail-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Account Details"}
                          />
                        </div>
                      </div>
                      <div
                        className={activeTab === "transaction-details-page" ? "active" : ""}
                        onClick={() => handleTabClick("transaction-details-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            fontSize={"font_size_extra_large"}
                            text={"Wallet/Credit"}
                          />
                        </div>
                      </div>
                      <div
                        className={activeTab === "brand-list-page" ? "active" : ""}
                        onClick={() => handleTabClick("brand-list-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            fontSize={"font_size_extra_large"}
                            text={"Brand List"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <OrganisationDetailsScreen
                    isActive={activeTab === "detail-page"}
                    organisationDetail={organisationDetail}
                    // org_id={org_id}
                    // adminGetDataSelf={adminGetDataSelf}
                  />
                  <>
                    <OrganisationTransactionList
                      isActive={activeTab === "transaction-details-page"}
                      // isActive={true}
                    />
                   </>
                    <OrganisationBrandList
                      isActive={activeTab === "brand-list-page"}
                      id={id}
                      // campaignId={campaignId}
                    />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    )
}
export default OrganisationParentDetails;