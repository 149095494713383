import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { CustomText, PrimaryCta, TeamMembers } from "../components";
import Icon from "../components/core_component/icon";

const OrgBrandListModal=(props)=>{
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          background: "var(--general-secondary-color)",
          borderColor: "var( --blue-blue-500)",
          textAlign: "center",
          zIndex: "5",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-around",
          minWidth: "400px",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.86)",
          zIndex: 10,
        },
      };
      
 return(
    <Modal isOpen={props.openOrgBrandListModal} onRequestClose={()=>{}} style={customStyles}>
        {
        <div className="org-cont" style={{marginBottom:"10px"}} >
        <div className="select-orgs" >
        <div className="select-org" >
                <div>
                <CustomText
                 p
                 text={props.title}
                 fontSize={"font_size_xl"}
                 textColor={"text_color_primary"}
                 fontWeight={"rubik_regular"}
                 />
                </div>
                <div onClick={()=>props.closeOrgBrandListModal(false)} className="close-icon-brand-list">
                <Icon iconName="CloseRoundButtonIcon" />
                </div>
        </div>
        </div>
        <div style={{justifyContent:"left"}} >
         {props.children}
        </div>
        </div>
        }
      </Modal>
 )
}
export default OrgBrandListModal;
